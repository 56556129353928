import axios from 'axios';
import { useCallback } from 'react';
import { ApiMethodEnum, ApiOperationEnum } from './enums';
import {
  ApiClientConfigInterface,
  ApiClientInterface,
  ApiInstance,
  ApiOperationsType,
  ApiRequestConfig,
  ApiResponse,
  RequestPropsInterface,
} from './types/Api.types';

const requestInternal = <T>(
  client: ApiInstance,
  url: string,
  method: ApiMethodEnum,
  params?: Record<string, unknown>,
  data?: Record<string, unknown> | FormData,
  additionalParams?: Partial<ApiRequestConfig>,
): Promise<ApiResponse<T>> => {
  switch (method) {
    case ApiMethodEnum.GET:
      return client.get(url, { params, ...additionalParams });
    case ApiMethodEnum.POST:
      return client.post(url, data, additionalParams);
    case ApiMethodEnum.PUT:
      return client.put(url, data, additionalParams);
    case ApiMethodEnum.DELETE:
      return client.delete(url, { data, ...additionalParams });
    default:
      throw new Error('Method not implemented');
  }
};

let clients: ApiClientInterface[] = [];
let op: ApiOperationsType = {};

export const createApiClient = <C, O>(
  config: ApiClientConfigInterface<C> | ApiClientConfigInterface<C>[],
  operations: ApiOperationsType<O, C>,
) => {
  let configs: ApiClientConfigInterface<C>[] = [];
  const addedClients: ApiClientInterface<C>[] = [];

  if (Array.isArray(config)) {
    configs = configs.concat(config);
  } else {
    configs.push(config);
  }

  configs.forEach(({ name, baseUrl, interceptors }: ApiClientConfigInterface<C>) => {
    const client = axios.create({
      baseURL: baseUrl,
    });

    if (interceptors) {
      const { request, response } = interceptors;

      if (request && (request.onFulfillment || request.onError)) {
        client.interceptors.request.use(request.onFulfillment, request.onError);
      }

      if (response && (response.onFulfillment || response.onError)) {
        client.interceptors.response.use(response.onFulfillment, response.onError);
      }
    }

    addedClients.push({
      name,
      client,
    });
  });

  clients = addedClients;
  op = operations;

  return clients;
};

const getClient = <C = any>(name: C | string): ApiInstance => {
  const client = clients.find((c: { name: any; client: ApiInstance }): boolean => c.name === name);

  if (!client) {
    throw new Error("Couldn't find axios instance for this operation");
  }

  return client.client;
};

export const useRequest = <T = any>(operation: number | ApiOperationEnum) => {
  const { method, client, url } = op[operation];
  const apiClient = getClient(client);

  const request = useCallback(
    (props?: RequestPropsInterface) => {
      const { urlParams = {}, params, data, additionalParams } = props || {};

      return requestInternal<T>(apiClient, url(urlParams), method, params, data, additionalParams);
    },
    [apiClient, method, url],
  );

  return {
    request,
  };
};
